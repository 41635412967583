import React from 'react'

function Cta() {
  return (
<div className="container p-4 cta-1 d-none d-md-block" style={{ background: "#0eafff" }}>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-1">
            <span className="float-start">
              <i className="fa-solid fa-phone fa-rotate-by text-light cta-1-i" style={{ "--fa-rotate-angle": "45deg" }}></i>
            </span>
          </div>
          <div className="col-8 d-flex-column align-items-center">
            <h4 className="text-light text-uppercase cta-1-h4 fw-bold p-0 m-0">Want a stress free relocation?</h4>
            <p className="p-0 m-0 text-light cta-1-p fw-light">Get the best transportation service according to your needs.</p>
          </div>
          <div className="col-3">
<a className="float-md-end text-uppercase cta-2-btn" href="#hero"><span className="btn-title">Get A Quote</span></a>          </div>
        </div>
      </div>  )
}

export default Cta