import React, { useState } from "react";
import { Link } from 'react-router-dom'
import logo_sticky from "../img/logo_sticky-min.png"
import logo from "../img/logo1-min.png"




function Header(props) {
  const [sticky, setSticky] = React.useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const handleNavOnClick = () => {
    if(window.innerWidth > 992){
      
     }else if(window.innerWidth < 992){
        handleNavCollapse()
    }
  }

  const handleStickyNav = () => {
    if (window.scrollY >= 5) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  window.addEventListener("scroll", handleStickyNav);



  return (
    <header className="header-southern">
      <div className={`container-fluid header-top ${sticky ? "topMargingOffset" : ""}`}>
        <div className={`row justify-content-between d-flex align-items-center ${sticky ? "top-bar-scale" : "top-bar"}`}>
          <div className="col text-start">
            <a className="btn topbar-link" href={`tel:${props.contact.phone}`}>
              <span>
                <i className="fa-solid fa-phone"></i>
              </span>
              <span className="ms-2">{props.contact.phone}</span>
            </a>
          </div>
          <div className="col text-center">
            <p className="m-0">
              {/* <b>GSTN:</b>123456789 */}
            </p>
          </div>
          <div className="col text-end">
            <a className="btn" href={`mailto:${props.contact.email}`}>
              <span>
                <i className="fa-regular fa-envelope"></i>
              </span>
              <span className="ms-2">{props.contact.email}</span>
            </a>
          </div>
        </div>
      </div>
      <div className={`container-fluid p-0 position ${sticky ? "sticky" : "nav-bt bg-navbar"}`}>
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <div className="nav-wrapper align-items-center">
            
            <a className="navbar-brand ms-3" href="/">
              <img className="logo" src={sticky ? logo_sticky : logo} alt="" />
            </a>
            <button
              className={`navbar-toggler me-3 ${isNavCollapsed ? "collapsed" : ""} position-relative ${sticky ? "sticky-toggler" : ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={!isNavCollapsed ? true : false}
              aria-label="Toggle navigation"
              onClick={handleNavCollapse}
            >
              <span> </span>
              <span> </span>
              <span> </span>
            </button>
            <div className={`${isNavCollapsed ? "collapse" : "pe-2"} navbar-collapse justify-content`} id="" style={{ display: "block !important" }}>
              <ul className={`navbar-nav`}>
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/" onClick={handleNavOnClick}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about_us" onClick={handleNavOnClick}>
                    About Us
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Services
                  </Link>
                  <ul className="dropdown-menu menu-border">
                    <li>
                      <Link className="dropdown-item" to="/car_transport" onClick={handleNavOnClick}>
                        Car Transport
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/bike_transport" onClick={handleNavOnClick}>
                        Bike Transport
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/office_shifting" onClick={handleNavOnClick}>
                        Office Relocation
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/house_relocation" onClick={handleNavOnClick}>
                        House Relocation
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/network" onClick={handleNavOnClick}>
                    Network
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/gallery" onClick={handleNavOnClick}>
                    Gallery
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact_us" onClick={handleNavOnClick}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            {/* <a href="tel:123456789" className="btn btn-outline-danger side me-3">
              <span className="me-1">
                <i className="fa-solid fa-phone"></i>
              </span>
              +91 00000000
            </a> */}
            <button className="btn btn-outline-danger side" type="button" onClick={props.showModal}>Get a Quote</button>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
