import React from "react";
import useForm from "./useForm";
import validateForm from "./validateForm";

function QuickQuote(props) {
  const { handleChange, values, handleSubmit, errors, deliveryError, isSending } = useForm(validateForm);

  

  return (
    <div className={`${props.form_hero ? "hero-form" : "form-contact float-none" } ${props.is_modal ? "float-none hero-form-modal" : "float-end"}`} id="get_quote">
      {props.is_modal ? <i className="fa-solid fa-circle-xmark close-modal" onClick={props.hideModal}></i> : ""}
      <h5 className="text-center text-uppercase">{props.form_contact?"Get In Touch With Us":"Get A Free Quote"}</h5>
      <form className="quick-quote-form mt-3 text-center" onSubmit={handleSubmit} method="POST">
        <div className="input-group mb-3 align-items-center">
          <span class={`input-group-text`} id="basic-addon1">
            <i class={`fa-solid fa-user`}></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Your Name"
            aria-label="Name"
            aria-describedby="basic-addon1"
            value={values.name}
            name="name"
            onChange={handleChange}
            autoComplete="off"
          />
          <i aria-hidden="true" class={`fa-solid fa-bg-white ${!errors.name ? "d-none" : errors.icon_name}`}></i>
        </div>
        <div className="input-group mb-3 align-items-center">
          <span className="input-group-text" id="basic-addon1">
            <i className="fa-solid fa-envelope"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Your Email"
            aria-label="Email"
            aria-describedby="basic-addon1"
            value={values.email}
            name="email"
            onChange={handleChange}
            autoComplete="off"
          />
          <i aria-hidden="true" class={`fa-solid fa-bg-white ${!errors.email ? "d-none" : errors.icon_email}`}></i>
        </div>
        <div className="input-group mb-3 align-items-center">
          <span className="input-group-text" id="basic-addon1">
            <i className="fa-solid fa-phone"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Your Phone No."
            aria-label="Phone"
            aria-describedby="basic-addon1"
            value={values.phone}
            name="phone"
            onChange={handleChange}
            autoComplete="off"
          />
          <i aria-hidden="true" class={`fa-solid fa-bg-white ${!errors.phone ? "d-none" : errors.icon_phone}`}></i>
        </div>
        <div className="input-group mb-3 align-items-center">
          <span className="input-group-text" id="basic-addon1">
            <i className="fa-solid fa-location-dot"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Location From"
            aria-label="LocationFrm"
            aria-describedby="basic-addon1"
            value={values.location_from}
            name="location_from"
            onChange={handleChange}
            autoComplete="off"
          />
          <i aria-hidden="true" class={`fa-solid fa-bg-white ${!errors.location_from ? "d-none" : errors.icon_locationFrom}`}></i>
        </div>
        <div className="input-group mb-3 align-items-center">
          <span className="input-group-text" id="basic-addon1">
            <i className="fa-solid fa-map-location-dot"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Location To"
            aria-label="LocationTo"
            aria-describedby="basic-addon1"
            value={values.location_to}
            name="location_to"
            onChange={handleChange}
            autoComplete="off"
          />
          <i aria-hidden="true" class={`fa-solid fa-bg-white ${!errors.location_to ? "d-none" : errors.icon_locationTo}`}></i>
        </div>

        <button type="submit" className="btn rounded-0 w-100 text-uppercase">
          Send Message
        </button>
      </form>
      <div class={`justify-content-center mt-2 ${isSending?"d-flex":"d-none"}`}>
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Sending...</span>
        </div>
      </div>
      <p className={`${deliveryError.color} text-center mt-2 mb-0 fs-6 mail-status ${!deliveryError.message ? "d-none" : "d-block"} ${deliveryError.sent ? "mail-sent" : ""}`}>
        {deliveryError.message}
      </p>
    </div>
  );
}

export default QuickQuote;
