import React from 'react'
import QuickQuote from './QuickQuote'

function Hero() {
  return (
<div className="container-fluid hero d-flex align-items-center justify-content-around" id='hero'>
        <div className="row d-flex content-hero align-items-center">
          <div className="col-6 col-hero-text">
            <div className="hero-text">
              <strong>Your Car</strong> & Its Safety <br />
              Is Our Priority
            </div>
          </div>
          <div className="col-6 col-custom">
          <QuickQuote form_hero={true}/>
          </div>
        </div>
      </div>  )
}

export default Hero